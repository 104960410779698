import axios from 'axios'
import {BASE_URL, API_VERSION, GETCATEGORIES, CATEGORIESBASEURL} from '../endpoints'

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'



export const getAllCategories = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL  + 'category' + '?size=300', options)
        .then((response) => {
            dispatch({ type: GET_ALL_CATEGORIES, payload: response.data})
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addCategory = (payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + 'category', payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_CATEGORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteCategory = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + 'category' + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_CATEGORY, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editCategory = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.patch(BASE_URL  + 'category' + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_CATEGORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}
import {
    GET_ALL_SHOWS,
    ADD_SHOW,
    EDIT_SHOW,
    DELETE_SHOW
} from '../actions/ShowActions'

const initialState = {
    data: [],
    allShows: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const ShowReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_SHOW: {
            let tempArray = Object.assign({}, state.allShows)
            tempArray.rows = [action.payload, ...state.allShows.rows]
            return {
                ...state,
                allShows: tempArray,
            }
        }
        case EDIT_SHOW: {
            let tempArray = Object.assign({}, state.allShows)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allShows: tempArray,
            }
        }
        case DELETE_SHOW: {
            let tempArray = Object.assign({}, state.allShows)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allShows: tempArray,
            }
        }
        case GET_ALL_SHOWS: {
            return {
                ...state,
                allShows: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ShowReducer

import axios from 'axios'
import env from "react-dotenv";
import { BASE_URL, API_VERSION, LOGIN, REGISTER, USERS, GETUSERS, GETUNAPPROVEDUSERS, USERBASEURL, GETALLUSERACTIVITY, PROJECTSBASEURL, ANALYTICSUSERACTIVITYBASEURL, APPROVEUSER } from '../endpoints'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_UNAPPROVED_USERS = 'GET_ALL_UNAPPROVED_USERS'
export const APPROVE_USER = 'APPROVE_USER'

export const ADD_USER = 'ADD_USER'
export const EDIT_USER = 'EDIT_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const DELETE_USER = 'DELETE_USER'
export const GET_ALL_USER_ACTIVITY = 'GET_ALL_USER_ACTIVITY'
export const GET_USER_ACTIVITY = 'GET_USER_ACTIVITY'
export const DELETE_USER_ACTIVITY = 'DELETE_USER_ACTIVITY'
export const GET_ANALYTICS_USERACTIVITY = 'GET_ANALYTICS_USERACTIVITY'

export const login = (payload) => (dispatch) => {
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + API_VERSION + LOGIN + '?appversion=admin', payload, apiHeader)
        .then(response => {
            dispatch({ type: LOGIN_USER, data: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getAllUsers = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + 'users' + '?size=5000', options)
        .then((response) => {
            dispatch({ type: GET_ALL_USERS, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getAllUnapprovedUsers = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + API_VERSION + GETUNAPPROVEDUSERS + '?size=300', options)
        .then((response) => {
            dispatch({ type: GET_ALL_UNAPPROVED_USERS, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const approveUser = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + API_VERSION + USERS + APPROVEUSER + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: APPROVE_USER, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const getAllUserActivity = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + API_VERSION + GETALLUSERACTIVITY, options)
        .then((response) => {
            dispatch({ type: GET_ALL_USER_ACTIVITY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getUserActivity = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + GETALLUSERACTIVITY + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_USER_ACTIVITY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const deleteUserActivity = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + GETALLUSERACTIVITY + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_USER_ACTIVITY, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const addUser = (payload) => (dispatch) => {
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + API_VERSION + REGISTER, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_USER, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteUser = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + 'users' + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_USER, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editUser = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.patch(BASE_URL +  'users/admin/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_USER, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getUserActivityAnalytics = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + USERBASEURL + ANALYTICSUSERACTIVITYBASEURL + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_ANALYTICS_USERACTIVITY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const resetPassword = (payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ` + accessToken,
        }
    }
    return axios.post(BASE_URL + 'users/changepassword', payload, apiHeader)
        .then(response => {
            // dispatch({ type: ADD_USER, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}
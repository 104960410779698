import { navigations } from 'app/navigations'
import { SET_USER_NAVIGATION } from '../actions/NavigationAction'

export const initialState = [
    {
        defaultNav: [
            {
                id: 0,
                name: 'Users',
                path: '/dashboard/users',
                iconText: 'S',
            },
            // {
            //     id: 1,
            //     name: 'User Activity',
            //     path: '/dashboard/user-activity',
            //     iconText: 'S',
            // },
            {
                id: 2,
                name: 'Roles',
                path: '/dashboard/roles',
                iconText: 'S',
            },
            {
                id: 3,
                name: 'Categories',
                path: '/dashboard/categories',
                iconText: 'S',
            },
            {
                id: 4,
                name: 'Shows',
                path: '/dashboard/shows',
                iconText: 'S',
            },

            {
                id: 8,
                name: 'User Logs',
                path: '/dashboard/userlogs',
                iconText: 'S',
            }
        ],
        userAccess: [],
        projectAccess: []
    },
]

const NavigationReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_NAVIGATION: {
            const tempProjectAccess = JSON.parse(window.localStorage.getItem('projectAccess'))

            let userAccess = state[0].defaultNav
            let projectAccess = []
            tempProjectAccess.map((item) => {
                userAccess.map((access) => {
                    if (item.title === access.name) {
                        projectAccess.push(access)
                    }
                })
            })

            console.log('state', projectAccess)

            // if (action.tabAccess) {
            //     userAccess = state[0].defaultNav.filter((item, index) => {
            //         return action.tabAccess[index].canView && item
            //     })
            //     if (action.tabAccess[8].canView) {
            //         projectAccess.push(...state[0].merchNav)
            //     }
            //     if (action.tabAccess[9].canView) {
            //         projectAccess.push(...state[0].wsopsNav)
            //     }
            // }
            // return [...action.tabAccess]
            return [{
                projectNav: state[0].projectNav,
                defaultNav: state[0].defaultNav,
                userAccess: projectAccess,
            }]
        }
        default: {
            return [...state]
        }
    }
}

export default NavigationReducer

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Analytics = Loadable(lazy(() => import("./Analytics")));
const Users = Loadable(lazy(() => import("./users")));
// const UserActivity = Loadable(lazy(() => import("./UserActivity")));
const Roles = Loadable(lazy(() => import("./Roles")));
const Categories = Loadable(lazy(() => import("./Category")));
const Shows = Loadable(lazy(() => import("./Shows")));
const Projects = Loadable(lazy(() => import("./Projects")));
const Areas = Loadable(lazy(() => import("./Areas")));
const SalesOffices = Loadable(lazy(() => import("./SalesOffices")));
const UserLog = Loadable(lazy(() => import("./UserLog")));

const UserActivityA = Loadable(lazy(() => import("./UserActivityA")));

const dashboardRoutes = [
    // {
    //     path: '/dashboard/default',
    //     element: <UserActivityA />,
    //     // element: <Analytics />,
    // },
    {
        path: '/dashboard/users',
        element: <Users />,
    },
    {
        path: '/dashboard/user-activity',
        element: <UserActivityA />,
    },
    {
        path: '/dashboard/roles',
        element: <Roles />,
    },
    {
        path: '/dashboard/categories',
        element: <Categories />,
    },
    {
        path: '/dashboard/shows',
        element: <Shows />,
    },
    {
        path: '/dashboard/projects',
        element: <Projects />,
    },
    {
        path: '/dashboard/areas',
        element: <Areas />,
    },
    {
        path: '/dashboard/salesoffices',
        element: <SalesOffices />,
    },
    {
        path: '/dashboard/userlogs',
        element: <UserLog />,
    },
]

export default dashboardRoutes

import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import UserReducer from './UserReducer'
import ProjectReducer from './ProjectReducer'
import CategoryReducer from './CategoryReducer'
import RoleReducer from './RoleReducer'
import ShowReducer from './ShowReducer'
import StoreReducer from './StoreReducer'
import AreaReducer from './AreaReducer'
import SalesOffice from './SalesOfficeReducer'
import InventoryChecklistReducer from './InventoryChecklistReducer'
import MerchblitzReducer from './MerchblitzReducer'
import StartInventoryReducer from './StartInventoryReducer'
import EndInventoryReducer from './EndInventoryReducer'
import VariantReducer from './VariantReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    user: UserReducer,
    project: ProjectReducer,
    category: CategoryReducer,
    role: RoleReducer,
    show: ShowReducer,
    store: StoreReducer,
    area: AreaReducer,
    salesOffice: SalesOffice,
    inventoryChecklist: InventoryChecklistReducer,
    merchblitz: MerchblitzReducer,
    startInventory: StartInventoryReducer,
    endInventory: EndInventoryReducer,
    variant: VariantReducer
})

export default RootReducer

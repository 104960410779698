import axios from 'axios'
import {BASE_URL, API_VERSION, GETSHOWS} from '../endpoints'

export const GET_ALL_SHOWS = 'GET_ALL_SHOWS'
export const ADD_SHOW = 'ADD_SHOW'
export const EDIT_SHOW = 'EDIT_SHOW'
export const DELETE_SHOW = 'DELETE_SHOW'

export const getAllShows = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL  + 'show' + '?size=300', options)
        .then((response) => {
            dispatch({ type: GET_ALL_SHOWS, payload: response.data})
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addShow = (payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + 'show', payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_SHOW, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteShow = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + 'show' + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_SHOW, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editShow = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    
    return axios.patch(BASE_URL + 'show'+ '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_SHOW, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}
import axios from 'axios'
import {BASE_URL, API_VERSION, GETROLES, ROLESBASEURL} from '../endpoints'

export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

export const getAllRoles = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + GETROLES + '?size=300', options)
        .then((response) => {
            dispatch({ type: GET_ALL_ROLES, payload: response.data})
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addRole = (payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + ROLESBASEURL, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_ROLE, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteRole = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + ROLESBASEURL + '/delete/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_ROLE, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editRole = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.patch(BASE_URL + ROLESBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_ROLE, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}